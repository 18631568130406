<i18n locale="th" lang="yaml" src="@i18n/service/service.th.yaml">
</i18n>
<i18n locale="th" lang="yaml" >
pre_service.main_title : "ใบตรวจรับโดรน"
pre_service.number_title : "เลขที่เอกสาร: "
pre_service.service_number : "{serviceNumber}"
pre_service.header.page_date : "เวลาที่พิมพ์: {datetime}"
pre_service.header.company_info : "ศูนย์บริการ"
pre_service.header.drone : "Drone"
pre_service.header.drone.serial : "เลขเครื่อง: {serial}"
pre_service.header.drone.pixhawk : "({pixhawk})"
pre_service.header.drone.model : "รุ่น: {name}"
pre_service.header.report_by : "รับแจ้งโดย"
pre_service.header.drone_receive_date : "โดรนเข้าศูนย์วันที่"
pre_service.header.inspection_date : "วันที่ตรวจเชคโดรน ก่อนส่งมอบ"
pre_service.header.flight_time : "จำนวนชั่วโมงบิน"
pre_service.header.firmware : "Firmware"
pre_service.header.devices : "อุปกรณ์ที่มากับ Drone"
pre_service.header.devices_unspecified: "ไม่ระบุ"

pre_service.footer.consent.acceptance : "&nbsp;&nbsp;( ) ข้าพเจ้าได้ตรวจสอบและยืนยันความถูกต้องของรายการข้างต้นเรียบร้อยแล้ว และส่งมอบโดรนพร้อมอุปกรณ์เป็นที่เรียบร้อย"
pre_service.footer.signature : "<div>..................................</div><pre>(                  )</pre><div>{description}</div>"
pre_service.footer.signature.customer : "ผู้เข้ารับบริการ"
pre_service.footer.signature.inspect_by : "ผู้ตรวจสอบ"
pre_service.footer.signature.deliver_by : "ผู้รับมอบ"
pre_service.footer.signature.approve_by : "ผู้อนุมัติ"
pre_service.footer.remark : "หมายเหตุ: "

pre_service.image_comment.front : "ด้านหน้า"
pre_service.image_comment.back : "ด้านหลัง"
pre_service.field.is_not_inspect.message : "ไม่ได้ตรวจรับก่อนซ่อม"
pre_service_modal.pageLoading.tip : "กำลัง Load ข้อมูล, กรุณารอสักครู่ ..."
tgd1x.pre_service_print.drone.inspect.label : "สภาพของ Drone"
</i18n>
<template>
	<a-modal class="printing-modal" :width="800" :visible="visible" :footer="null" :mask-closable="false" @cancel="handleCancel">
		<a-spin wrapper-class-name="tunjai-page-loading" :spinning="loading" size="large" :tip="$t('pre_service_modal.pageLoading.tip')">
			<div id="print-pre-delivery">
				<div class="page-header">
					<img class="hg-logo" src="@assets/images/hiveground_logo.png"/>
					<div class="modal-name">
						<my-page-header :title="$t('pre_service.main_title')" />
					</div>
					<div v-if="setFormData" class="service-no">
						<div style="font-size: 13px;" class="service-no-title">{{$t('pre_service.number_title')}}</div>
						<div style="font-size: 13px;">{{$t('pre_service.service_number', { serviceNumber: serviceNumber })}}</div>
						<div class="form-detail">
							<span class="service-no-title">{{ $t('service.header.page_form_title') }}</span>
							{{ $t('service.header.page_form_content', { form: setFormData.formCode }) }}
						</div>
						<div class="form-detail">
							<span class="service-no-title">{{ $t('service.header.page_revision_title') }}</span>
							{{ $t('service.header.page_revision_content', { revision: setFormData.revision }) }}
						</div>
						<div class="form-detail">
							<span class="service-no-title">{{ $t('service.header.page_effective_date_title') }}</span>
							{{ $t('service.header.page_effective_date_content', { effectiveDate: setFormData.effectiveDate }) }}
						</div>
					</div>
				</div>

				<div class="service-detail-container">
					<div class="service-col">
						<label>{{ $t('pre_service.header.company_info') }}</label>
						<div class="service-val">
							<div class="service-val-row">
								{{ displayCompany.name }}
							</div>
							<div class="service-val-row">
								{{
									(displayCompany.address ? displayCompany.address + ' ' : '') + displayCompany.province + ' ' + displayCompany.postal_code
								}}
							</div>
							<div v-if="displayCompany.contact_phone" class="service-val-row">
								<PhoneLink :value="displayCompany.contact_phone" />
							</div>
							<div v-if="displayCompany.contact_email" class="service-val-row info">
								{{ displayCompany.contact_email }}
							</div>
						</div>
					</div>
					<div class="service-col">
						<label>{{ $t('pre_service.header.drone') }}</label>
						<div class="service-val">
							<div class="service-val-row">
								{{ $t('pre_service.header.drone.serial', { serial: droneSerial }) }}
							</div>
							<div class="service-val-row">
								{{ $t('pre_service.header.drone.pixhawk', { pixhawk: dronePixhawk }) }}
							</div>
							<div class="service-val-row">
								{{ $t('pre_service.header.drone.model', { name: droneModelName }) }}
							</div>
							<div class="service-val-row">
									{{ $t('service.header.drone_owner', { name: ownerName }) }}
							</div>
							<div v-if="ownerIdentityNo" class="service-val-row">
								{{ $t('service.header.drone_owner_identity_no', { id: ownerIdentityNo }) }}
							</div>
							<div v-else class="service-val-row">
								{{ $t('service.header.drone_owner_identity_no_blank') }}
							</div>
							<div class="service-val-row">
								<PhoneLink :value="ownerPhone" />
								<span style="margin-left: 8px">{{ $t('common.province') }}{{ ' ' + ownerProvince }}</span>
							</div>
						</div>
					</div>
					<div class="service-col">
						<label>{{ $t('service.field.customer') }}</label>
						<div v-if="isSamePerson" class="service-val">
							<div class="service-val-row">
								{{ ownerName }}
							</div>
							<div v-if="ownerIdentityNo" class="service-val-row">
								{{ $t('service.header.drone_owner_identity_no', { id: ownerIdentityNo }) }}
							</div>
							<div v-else class="service-val-row">
								{{ $t('service.header.drone_owner_identity_no_blank') }}
							</div>
							<div class="service-val-row">
								<PhoneLink :value="ownerPhone" />
								<span style="margin-left: 8px">{{ $t('common.province') }}{{ ' ' + ownerProvince }}</span>
							</div>
						</div>
						<div v-else class="service-val">
							<div class="service-val-row">
								{{ customerName }}
							</div>
							<div class="service-val-row" style="display: flex">
								{{ $t('service.header.drone_owner_identity_no_blank')}}
							</div>
							<div class="service-val-row">
								<PhoneLink :value="customerPhone" />
								<span style="margin-left: 8px">{{ $t('common.province') }}{{ ' ' + customerProvince }}</span>
							</div>
						</div>
					</div>
					<div class="service-col">
						<label>{{ $t('pre_service.header.drone_receive_date') }}</label>
						<div class="service-val">
							<div class="service-val-row">
								<span v-if="droneReceiveDateTime">
									{{ droneReceiveDateTime }}
								</span>
								<span v-else>
									{{ $t('common.unspecify') }}
								</span>
							</div>
						</div>
					</div>
					<div class="service-col">
						<label>{{ $t('pre_service.header.report_by') }}</label>
						<div class="service-val">
							<div class="service-val-row">
								<span v-if="openByUsername">
									{{ openByUsername }}
								</span>
								<span v-else>
									{{ $t('common.unspecify') }}
								</span>
							</div>
						</div>
					</div>
					<div class="service-col">
						<label>{{ $t('pre_service.header.flight_time') }}</label>
						<div class="service-val">
							<FlightTimeView :time-seconds="flightTime" />
						</div>
					</div>
					<div class="service-col">
						<label>{{ $t('pre_service.header.firmware') }}</label>
						<div class="service-val">
							{{ firmwareVersion }}
						</div>
					</div>
					<div class="service-col">
						<label>{{ $t('pre_service.header.devices') }}</label>
						<div class="service-val" style="display: flex; flex-direction: column">
							<div v-for="item in devices" :key="item.id">
								{{ $tenum('drone.devices', item) }}
							</div>
							<div v-if="others !== ''" class="service-val">
								{{ others }}
							</div>
						</div>
					</div>
				</div>
				<BlockPane :block="isNotInspect">
					<span slot="message">
						{{ $t('pre_service.field.is_not_inspect.message') }}
					</span>

					<div class="checklist-row checklist-row-title checklist-row-border">
						<label class="title"> <span>1</span> {{ $t('tgd1x.pre_service_print.drone.inspect.label') }} </label>
					</div>
					<div id="droneDiagram">
						<PreServiceTGD1XDiagramPrint :service="service" :inspection-data="inspectedData" />
					</div>
					<PreServiceTGD1XChecklistPrint ref="checklistRef" :service="service" />
				</BlockPane>
				<div v-html="$t('pre_service.footer.consent.acceptance')" />
				<div class="signature">
					<div
						class="signature-item"
						v-html="$t('pre_service.footer.signature', { description: $t('pre_service.footer.signature.customer') })"/>
					<div
						class="signature-item"
						v-html="$t('pre_service.footer.signature', { description: $t('pre_service.footer.signature.inspect_by') })"/>
					<div
						class="signature-item"
						v-html="$t('pre_service.footer.signature', { description: $t('pre_service.footer.signature.deliver_by') })"/>
					<div
						class="signature-item"
						v-html="$t('pre_service.footer.signature', { description: $t('pre_service.footer.signature.approve_by') })"/>
				</div>
				<div class="page-remark">
					{{ remark ? $t('pre_service.footer.remark') + remark : $t('pre_service.footer.remark') + '-' }}
				</div>
			</div>
			<a-button type="info" icon="printer" @click="print"> print </a-button>
		</a-spin>
	</a-modal>
</template>

<script>
import { mapState } from 'vuex'
import PhoneLink from '@components/common/PhoneLink.vue'
import PreServiceTGD1XChecklistPrint from '@components/service/tgd1x/PreServiceTGD1XChecklistPrint.vue'
import PreServiceTGD1XDiagramPrint from '@components/service/tgd1x/PreServiceTGD1XDiagramPrint.vue'
import FlightTimeView from '@components/common/FlightTimeView.vue'
import axios from 'axios'
import ApiError from '@utils/errors/ApiError'
import BlockPane from '@components/common/BlockPane.vue'
import { findFormData, FORM_CODE } from '@utils/documentUtil'

export default {
	components: {
		PreServiceTGD1XChecklistPrint,
		PreServiceTGD1XDiagramPrint,
		PhoneLink,
		FlightTimeView,
		BlockPane,
	},

	props: {
		service: {
			type: null,
			default: () => [],
		},
		preDelivery: {
			type: null,
			default: () => {},
		},
		canUpdate: {
			type: Boolean,
			default: false,
		},
		isNotInspect: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			visible: false,
			loading: false,
			flightTime: '',
			droneReceiveDateTime: '',
			inspectedDateTime: '',
			serviceId: '',
			serviceNumber: '',
			droneSerial: '',
			dronePixhawk: '',
			droneModelName: '',
			customerName: '',
			customerPhone: '',
			customerProvince: '',
			printDate: '',
			openByUsername: '',
			firmwareVersion: '',
			inspectedData: '',
			isPrtClicked: false,
			devices: [],
			others: '',
			remark: '',
			ownerName: '',
			ownerIdentityNo: null,
			ownerPhone: '',
			ownerProvince: '',
			isSamePerson: false,
		}
	},

	computed: {
		...mapState('user', ['companies']),
		...mapState('auth', ['currentCompany']),
		...mapState('form', ['forms']),
		displayCompany() {
			return this.companies.find((c) => c.id == this.currentCompany.id)
		},
		containerCssClasses() {
			return [
				'pre_service-checklist',
				{
					'checklist-readonly': !this.canUpdate,
				},
			]
		},
		setFormData() {
			const formCode = FORM_CODE.PRE_SERVICE
			return findFormData(this.forms, formCode)
		}
	},
	methods: {
		setData(data) {
			this.$nextTick(() => {
				this.$refs.checklistRef.setData(data)
			})
		},
		_loadPreServcie() {
			this.loading = true
			axios
				.get('/api/services/' + this.serviceId + '/pre-service')
				.then((response) => {
					this.firmwareVersion = response.data.data.pre_service.firmware.name
					this.inspectedData = response.data.data.pre_service.inspectionData
					this.remark = response.data.data.pre_service.remark
					if (response.data.data.pre_service.devicesData) {
						this.devices =
							response.data.data.pre_service.devicesData.devices.length === 0
								? [`$t(pre_service.header.devices_unspecified)`]
								: response.data.data.pre_service.devicesData.devices
						this.others = response.data.data.pre_service.devicesData.others
					} else {
						this.devices = [`$t(pre_service.header.devices_unspecified)`]
					}
					if (response.data.data.pre_service.inspected_datetime) {
						const date = this.$dayjs(response.data.data.pre_service.inspected_datetime)
						this.droneReceiveDateTime = date.format('YYYY-MM-DD') + ' เวลา ' + date.format('HH:mm')
					}
				})
				.catch((error) => {
					this.$message.error(ApiError.getDisplayErrorMessage(this, error))
					this.visible = false
				})
				.finally(() => {
					this.loading = false
				})
		},

		openModal(data) {
			this.visible = true
			this.serviceId = data.serviceId
			this.flightTime = data.flightTime
			this.inspectedDateTime = data.inspectedDateTime
			this.serviceNumber = data.serviceNumber
			this.droneSerial = data.droneSerial
			this.dronePixhawk = data.dronePixhawk
			this.droneModelName = data.droneModelName
			this.customerName = data.customerName
			this.customerPhone = data.customerPhone
			this.customerProvince = data.customerProvince
			this.openByUsername = data.openByUsername
			this.ownerName = data.owner.name
			this.ownerIdentityNo =  data.owner.identity_no
			this.ownerPhone = data.owner.phone
			this.ownerProvince = data.owner.province
			this.isSamePerson = this.customerName === this.ownerName
			const currentDateTime = new Date()
			this.printDate = currentDateTime.toLocaleDateString() + ':' + currentDateTime.toLocaleTimeString()
			this._loadPreServcie()
		},

		handleCancel() {
			this.visible = false
		},

		print() {
			if (!this.isPrtClicked) {
				const prtDroneUpper = document.querySelectorAll('.printing-modal .lower-canvas')[0].toDataURL()
				const prtDroneLower = document.querySelectorAll('.printing-modal .lower-canvas')[1].toDataURL()
				const commentDroneUpper = document.querySelectorAll('.printing-modal .image-comment-items')[0].innerHTML
				const commentDroneLower = document.querySelectorAll('.printing-modal .image-comment-items')[1].innerHTML
				document.getElementById('droneDiagram').innerHTML = `<div style="font-family: 'Prompt', Helvetica, Arial, sans-serif;
						text-align: center;
						margin-bottom : 8px;
						color : #1890ff;
						font-size : 1.1em;">ด้านหน้า</div>
					<img src="${prtDroneUpper}" style="display: block;
						width: 60%;
						margin-left: auto;
						margin-right: auto;">
					<div style="display: block;
						width: 60%;
						margin-left: auto;
						margin-right: auto;">
							${commentDroneUpper}
					</div>
					<div style="font-family: 'Prompt', Helvetica, Arial, sans-serif;
						text-align: center;
						margin-bottom : 8px;
						color : #1890ff;
						font-size : 1.1em;">ด้านหลัง</div>
					<img src="${prtDroneLower}" style="display: block;
						width: 60%;
						margin-left: auto;
						margin-right: auto;">
					<div style="display: block;
						width: 60%;
						margin-left: auto;
						margin-right: auto;">
							${commentDroneLower}
					</div>`
				this.isPrtClicked = true
			}
			const prtHtml = document.getElementById('print-pre-delivery').innerHTML
			let stylesHtml = ''

			for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
				stylesHtml += node.outerHTML
			}

			// Open the print window
			const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')
			WinPrint.document.write(`<!DOCTYPE html>

				<html>
  				<head>
    				${stylesHtml}
  				</head>
  				<body>
    				${prtHtml}
  				</body>
				</html>`)
			setTimeout(() => {
				WinPrint.focus()
				WinPrint.document.close()
				WinPrint.print()
				WinPrint.close()
			}, 2000)
		},
	},
}
</script>

<style lang="less" scoped>
@part-break-screen: @screen-lg;

.printing-modal {
	width: 700px;
	div {
		font-size: 0.7rem;
	}
}
.page-header {
	display: flex;
	flex-direction: row;
	padding-top: 15px;
  justify-content: space-between;
}
.info {
	color: @info-color;
}
.service-no {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	padding-left: 30px;
}
.service-no-title {
	color: @primary-color;
}
.form-detail {
	font-style: italic;
}
.modal-name {
	justify-content: center;
	align-items: center;
	text-align: center;
}
.hg-logo {
  max-width: 25%;
  height: 40%;
}
.service-detail-container {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	background-color: @white;
	margin-bottom: 8px;
	border-radius: @border-radius-base;

	.service-col {
		flex-basis: 50%;
		display: flex;
		flex-wrap: nowrap;
		min-width: 0;
		border: 1px solid @gray-4;
		> label {
			font-family: @font-family-title;
			color: @primary-color;
			min-width: 100px;
			flex-basis: 100px;
			background-color: @gray-3;
			padding: 4px 8px;
		}
		> .service-val {
			padding: 4px 8px;
			min-width: 0;
			.service-val-row {
				margin-bottom: 2px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}
.signature {
	display: flex;
	position: relative;
	padding: 10px;
	margin-top: 8px;
	margin-bottom: 0px;

	.signature-item {
		display: flex;
		flex-basis: 25%;
		align-items: center;
		flex-direction: column;
		position: relative;
		margin-right: 8px;
	}
}
.page-remark {
	display: flex;
	margin-top: 8px;
	justify-content: left;
	align-items: left;
}
@media print {
	div {
		font-size: 0.7rem;
	}
	.service-col {
		> label {
			-webkit-print-color-adjust: exact;
		}
	}
}
</style>
