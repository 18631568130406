<i18n locale="th" lang="yaml" src="@i18n/service/pre-service.th.yaml"></i18n>
<i18n locale="th" lang="yaml" >
pre_service.inspect : "ข้อมูลการตรวจรับ"


pre_service.devices : "อุปกรณ์ที่มากับ Drone"
pre_service.devices.others.placeholder : "เช่น สายไฟ"
pre_service.devices.batteries.placeholder : "S/N หรือ กรอกไม่ระบุ"

pre_service.drone : "ข้อมูล Drone ตอนรับเครื่อง"
pre_service.drone.none : "เป็นงานซ่อมเฉพาะอุปกรณ์เท่านั้น (ไม่ต้องทิ้ง Drone ไว้)"

pre_service.field.inspected_by.placeholder : "ตรวจรับโดย"

pre_service.field.inspection : "สภาพของ Drone สำหรับ รุ่น {model}"
pre_service.field.inspection.help : "วงกลม ตำแหน่งที่เสียหาย / ชิ้นส่วนหาย"

pre_service.field.checklist : "การทดสอบการทำงานของ Drone ก่อนรับบริการ"
pre_service.field.remark.placeholder : "เช่น สภาพของ Drone หรือ อุปกรณ์อื่น ๆ เพิ่มเติม หรือ สาเหตุที่ไม่ได้ตรวจรับก่อน เป็นต้น"
pre_service.field.is_not_inspect.message : "ไม่ได้ตรวจรับก่อนซ่อม"

pre_service.action.ask_leave.title : "ยืนยันออกจากหน้าจอนี้"
pre_service.action.ask_leave.message : "คุณได้แก้ไขข้อมูลการตรวจรับ และ ยังไม่ได้บันทึกเข้าระบบ, ยืนยันต้องการยกเลิกการแก้ไขนี้ ?"

pre_service.action.ask_reset : "ยกเลิกการแก้ไข"
pre_service.action.ask_reset.title : "@:pre_service.action.ask_reset"
pre_service.action.ask_reset.message : "คุณต้องการยกเลิกการแก้ไข และ นำข้อมูลเดิมในระบบกลับมา ?"

pre_service.action.update.no_change : "ไม่ได้มีการแก้ไขค่า"
pre_service.action.update.confirm.title : "ยืนยันการแก้ไขค่า"
pre_service.action.update.confirm.message : "คุณต้องการบันทึกการแก้ไขใบตรวจรับ Drone นี้ ?"
pre_service.action.update.confirm.success : "ทำการบันทึกใบตรวจรับ Drone สำหรับงานซ่อม {name} เรียบร้อย"

pre_service.files : "ข้อมูลอื่น ๆ ประกอบการตรวจรับ"
pre_service.images.label : "รูป/วีดีโอ ประกอบการตรวจรับ"
pre_service.others.label : "ไฟล์ประกอบอื่น ๆ เช่น Log Files"
pre_service.document_images.label : "รูปใบตรวจรับเครื่องพร้อมลายเซ็นต์"
pre_service.document_files.label : "ไฟล์ใบตรวจรับเครื่องพร้อมลายเซ็นต์"

pre_service.drone.received.button: "ใบตรวจรับโดรน"
</i18n>

<template>
	<div v-show="!loading" class="page-padding">
		<template v-if="canUpdatePreService">
			<div class="drone-received-container">
				<a-button v-if="!service.is_device_only" icon="printer" @click="openPrintModal">
					{{$t('pre_service.drone.received.button')}}
				</a-button>
				<a-button class="right-button" @click="handleReset">
					{{$t('pre_service.action.ask_reset')}}
				</a-button>
				<a-button type="primary" class="btn-submit" @click="handleSave">
					{{$t('common.save')}}
				</a-button>
			</div>

			<ErrorMessagePane :error="saveError" />

			<div class="pre_service-not-inspect">
				<a-checkbox
					v-model="preServiceValue.is_not_inspect">
					{{$t('pre_service.field.is_not_inspect')}}
				</a-checkbox>
			</div>
			<hr />
		</template>

		<div class="mytab-section-title">
			{{$t('pre_service.drone')}}
		</div>
		<div v-if="service.is_device_only" class="pre_service-subsection">
			{{$t('pre_service.drone.none')}}
		</div>
		<div v-else class="pre_service-subsection">
			<div class="pre_service-row">
				<label>{{$t('pre_service.field.firmware')}} * : </label>
				<div v-if="canUpdatePreService" class="pre_service-input-block">
					<FirmwareSelect
						v-model="preServiceValue.firmware_id"
						:firmware-set-id="droneData.model.firmware_set_id"/>
				</div>
				<div v-else class="pre_service-input-view">
					{{preService.firmware ? preService.firmware.name : $t('common.unspecify')}}
				</div>
			</div>
			<div class="pre_service-row">
				<label>{{$t('pre_service.field.flight_time')}} * : </label>
				<div v-if="canUpdatePreService" class="pre_service-input-block">
					<FlightTimeInput
						v-model="preServiceValue.flight_time_sec"/>
				</div>
				<div v-else class="pre_service-input-view">
					<FlightTimeView :time-seconds="preServiceValue.flight_time_sec" />
				</div>
			</div>
		</div>

		<BlockPane :block="preServiceValue.is_not_inspect">
			<span slot="message">
				{{$t('pre_service.field.is_not_inspect.message')}}
			</span>
			<div class="mytab-section-title">
				{{$t('pre_service.inspect')}}
			</div>
			<div class="pre_service-subsection">
				<div class="pre_service-row">
					<label>{{$t('pre_service.field.inspected_by')}} * : </label>
					<div v-if="canUpdatePreService" class="pre_service-input-block">
						<UserCompanySelect
							v-model="preServiceValue.inspected_by_id"
							:company-id="service.company_id"
							:allow-clear="true"
							:placeholder="$t('pre_service.field.inspected_by.placeholder')"/>
					</div>
					<div v-else class="pre_service-input-view">
						<my-router-link v-if="preService.inspected_by_id > 0" name="user/view" :param-value="preService.inspected_by_id">
							<ImageName :name="preService.inspectedByData.user.username" :src="preService.inspectedByData.user.image_url">
								<a-icon type="user" />
							</ImageName>
						</my-router-link>
						<span v-else>{{$t('common.unspecify')}}</span>
					</div>
				</div>
				<template v-if="canUpdatePreService">
					<div class="pre_service-row">
						<label>{{$t('pre_service.field.inspected_date')}} * : </label>
						<div class="pre_service-input-block">
							<MyDatePicker
								v-model="inspectedDate"
								format="DD MMMM YYYY"/>
						</div>
					</div>
					<div class="pre_service-row">
						<label>{{$t('pre_service.field.inspected_time')}} * : </label>
						<div class="pre_service-input-block">
							<a-time-picker
								v-model="inspectedTime"
								input-read-only
								format="HH:mm"
								value-format="HH:mm"/>
						</div>
					</div>
				</template>
				<div v-else class="pre_service-row">
					<label>{{$t('pre_service.field.inspected_date')}} * : </label>
					<div class="pre_service-input-view">
						<span v-if="$notEmpty(preService.inspected_datetime)">
							{{$dayjs(preService.inspected_datetime).format('LLL')}}
						</span>
						<span v-else>
							{{$t('common.unspecify')}}
						</span>
					</div>
				</div>
			</div>

			<div class="mytab-section-title">
				{{$t('pre_service.field.devices')}}
			</div>
			<div class="pre_service-subsection">
				<div class="pre_service-row">
					<a-checkbox-group
						:value="devicesData.devices"
						:options="deviceOptions"
						@change="handleDeviceDefaultList" />
				</div>
				<div class="pre_service-row">
					<label>{{$tenum('service.pre_service.devices','others')}} : </label>
					<div class="pre_service-input-block">
						<a-input
							v-model="devicesData.others"
							style="max-width:350px"
							:read-only="!canUpdatePreService"
							:placeholder="$t('pre_service.devices.others.placeholder')"/>
					</div>
				</div>
				<div class="pre_service-row">
					<label>{{$tenum('service.pre_service.devices','batteries')}} : </label>
					<div class="pre_service-input-block">
						<label>1. </label>
						<a-input
							v-model="devicesData.batteries.no1"
							style="width:250px"
							:read-only="!canUpdatePreService"
							:placeholder="$t('pre_service.devices.batteries.placeholder')"/>
					</div>
					<div class="pre_service-input-block">
						<label>2. </label>
						<a-input
							v-model="devicesData.batteries.no2"
							style="width:250px"
							:read-only="!canUpdatePreService"
							:placeholder="$t('pre_service.devices.batteries.placeholder')"/>
					</div>
				</div>
			</div>

			<template v-if="!service.is_device_only">
				<div class="mytab-section-title">
					{{$t('pre_service.field.inspection',{ model: droneData.model.name })}}
					<div class="mytab-section-description">
						{{$t('pre_service.field.inspection.help')}}
					</div>
				</div>
				<div  class="pre_service-subsection-full">
					<component
							:is="inspectComponent"
							ref="inspection"
							:service="service"
							:checklist-type="preService.checklist_type"
							:can-update="canUpdatePreService"/>
				</div>
				<div class="mytab-section-title">
					{{$t('pre_service.field.checklist')}}
				</div>
				<div class="pre_service-subsection-full">
					<component
						:is="checklistComponent"
						ref="checklist"
						:can-update="canUpdatePreService"
						:service="service"
						:checklist-type="preService.checklist_type"/>
						<PreServicePrintModal
							ref="preServicePrintModalRef"
							:is-not-inspect="preServiceValue.is_not_inspect"
							:service="service"/>
				</div>
			</template>
		</BlockPane>
		<template v-if="canUpdatePreService || serviceFiles.length > 0 || serviceDocumentFiles.length > 0">
			<div class="mytab-section-title">
				{{$t('pre_service.files')}}
			</div>
			<div class="pre_service-subsection files-upload">
				<div class="files-component">
					<ServiceFilesPanel
						ref="serviceFilesRef"
						:error="componentError"
						:service="service"
						:label="$t('pre_service.images.label')"
						:others-label="$t('pre_service.others.label')"
						:can-update="canUpdatePreService"/>
				</div>
				<div class="files-component">
					<ServiceFilesPanel
						ref="serviceDocumentFilesRef"
						:error="componentError"
						:service="service"
						:label="$t('pre_service.document_images.label')"
						:others-label="$t('pre_service.document_files.label')"
						:can-update="canUpdatePreService"/>
				</div>
			</div>
		</template>


		<div class="mytab-section-title">
			{{$t('pre_service.field.remark')}}
		</div>
		<div class="pre_service-subsection-full">
			<a-textarea
				v-model="preServiceValue.remark"
				style="max-width:800px;"
				:auto-size="{ minRows: 4,maxRows: 10 }"
				:read-only="!canUpdatePreService"
				:placeholder="$t('pre_service.field.remark.placeholder')"/>
		</div>

		<div v-if="canUpdatePreService" class="page-action-right">
			<a-button @click="handleReset">
				{{$t('pre_service.action.ask_reset')}}
			</a-button>
			<a-button type="primary" class="btn-submit" @click="handleSave">
				{{$t('common.save')}}
			</a-button>
		</div>
	</div>
</template>

<script>
import PageMixin from "@mixins/PageMixin.vue"
import ServiceObjectMixin from "@/src/mixins/service/ServiceObjectMixin.vue"
import {transformToJsonProp,replaceDeepProperty} from "@utils/objectUtil"
import {Checkbox,TimePicker,Input} from "ant-design-vue"
import FirmwareSelect from "@components/firmware/FirmwareSelect.vue"
import MyDatePicker from "@components/input/MyDatePicker.vue"
import UserCompanySelect from "@components/user/UserCompanySelect.vue"
import BlockPane from "@components/common/BlockPane.vue"
import ImageName from "@components/common/ImageName.vue"
import FlightTimeInput from "@components/input/FlightTimeInput.vue"
import FlightTimeView from "@components/common/FlightTimeView.vue"
import InvalidChecklistType from "@components/service/InvalidChecklistType.vue"
import PreServiceTGD1XInspection from "@components/service/tgd1x/PreServiceTGD1XInspection.vue"
import PreServiceTGD1XChecklist from "@components/service/tgd1x/PreServiceTGD1XChecklist.vue"
import ErrorMessagePane from "@components/common/ErrorMessagePane.vue"
import {scrollTopContent,getEnumSelectOptions} from "@utils/formUtil"
import axios from "axios"
import _isEqual from "lodash/isEqual"
import ModelError from "@utils/errors/ModelError"
import ServiceFilesPanel from "@components/service/ServiceFilesPanel.vue"
import PreServicePrintModal from '@components/service/PreServicePrintModal.vue'


function _defaultDevicesData() {
	return {
		devices : [] ,
		batteries : {
			no1 : '',
			no2 : '',
		},
		others : '' ,
	}
}
function _defaultPreService() {
	return {
		is_not_inspect : false,
		firmware_id : undefined,
		flight_time_sec : undefined,
		inspected_by_id : undefined,
		remark : undefined,
	}
}

export default {
	components : {
		"a-checkbox-group" : Checkbox.Group, "a-checkbox" : Checkbox,
		"a-time-picker" : TimePicker, "a-textarea" : Input.TextArea,
		FirmwareSelect , FlightTimeView, FlightTimeInput ,
		InvalidChecklistType ,
		PreServiceTGD1XInspection , PreServiceTGD1XChecklist ,
		UserCompanySelect, MyDatePicker, BlockPane , ImageName,
		ErrorMessagePane, ServiceFilesPanel, PreServicePrintModal
	} ,
	mixins : [PageMixin,ServiceObjectMixin] ,
	props : {
		service : {
			type : null,
			default : () => []
		} ,
	} ,
	data() {
		return {
			loading : false,
			preService : {} ,
			serviceFiles: [],
			serviceDocumentFiles: [],
			preServiceValue : _defaultPreService() ,
			devicesData : _defaultDevicesData() ,
			inspectedDate : undefined ,
			inspectedTime : undefined,

			ignorePreventExit : false,
			oldFormData : {} ,
			saveError : undefined,
 		}
	} ,
	computed : {
		canUpdatePreService() {
			return this.canUpdate
		} ,
		inspectComponent() {
			if (this.service.id > 0) {
				switch (this.preService.checklist_type) {
					case 'tgd1x' :
						return PreServiceTGD1XInspection
					default :
						return InvalidChecklistType
				}
			}
			return undefined
		} ,
		checklistComponent() {
			if (this.service.id > 0) {
				switch (this.preService.checklist_type) {
					case 'tgd1x' :
						return PreServiceTGD1XChecklist
					default :
						return InvalidChecklistType
				}
			}
			return undefined
		},
		deviceOptions() {
			const options = ['remote','tablet','charger','charging_panel']
			return getEnumSelectOptions(this,'service.pre_service.devices',options)
		} ,
		componentError() {
			return this.saveError instanceof ModelError ? this.saveError : undefined
		}
	} ,
	watch : {
		$route(newVal) {
			if (!this.preService || newVal.params.id != this.preService.id) {
				this.fetchPreService()
			}
		}
	} ,
	mounted() {
		this.fetchPreService();
	} ,
	beforeMount() {
		window.addEventListener("beforeunload",this.preventExit)
	} ,
	beforeDestroy() {
		window.removeEventListener("beforeunload",this.preventExit)
	} ,
	methods : {
		openPrintModal () {
			const checklistC = this.$refs.checklist
			if (checklistC && typeof checklistC.getData === 'function') {
					this.$refs.preServicePrintModalRef.openModal({
						serviceId: this.service.id,
						serviceNumber: this.service.service_no,
						flightTime: this.preServiceValue.flight_time_sec,
						droneSerial: this.service.droneData.drone.serial_no,
						dronePixhawk: this.service.droneData.drone.pixhawk_serial_no,
						droneModelName: this.service.droneData.model.name,
						customerName: this.service.customer_name,
						customerPhone: this.service.customer_phone,
						customerProvince: this.service.customer_province,
						openByUsername: this.service.openByData.user.username,
						owner: this.service.droneData.owner,
					})
					this.$refs.preServicePrintModalRef.setData(checklistC.getData())
			}
		},
		fetchPreService() {
			const serviceId = this.$route.params.id
			if (!serviceId) {
				return
			}
			this.loading = true
			axios.get("/api/services/"+serviceId+"/pre-service").then((response) => {
				this.preService = response.data.data.pre_service
				this.serviceFiles = response.data.data.service_files
				this.serviceDocumentFiles = response.data.data.service_document_files
				this.initPreService()
			}).catch((error) => {
				this.fetchError(error)
			}).finally(() => {
				this.loading = false
			})
		} ,
		initPreService() {
			this.saveError = undefined
			this.inspectedDate = undefined
			this.inspectedTime = undefined

			this.preServiceValue = _defaultPreService()
			if (this.preService) {
				replaceDeepProperty(this.preServiceValue,this.preService)
			}

			if (this.preService.inspected_datetime) {
				const date = this.$dayjs(this.preService.inspected_datetime)
				this.inspectedDate = date.format('YYYY-MM-DD')
				this.inspectedTime = date.format('HH:mm')
			}

			if(this.preService.devicesData) {
				this.devicesData = {
					..._defaultDevicesData() ,
					...this.preService.devicesData ,
				}
			} else {
				this.devicesData = {
					..._defaultDevicesData()
				}
			}

			this.$nextTick(() => {
				const inspectComp = this.$refs.inspection
				if (inspectComp && 'setData' in inspectComp) {
					if (this.preService.inspectionData) {
						inspectComp.setData(this.preService.inspectionData)
					} else {
						inspectComp.setData({})
					}
				}
				const checklistComp = this.$refs.checklist
				if (checklistComp) {
					if (this.preService.checklistData) {
						checklistComp.setData(this.preService.checklistData)
					} else {
						checklistComp.setData({})
					}
				}
				if (this.$refs.serviceFilesRef) {
					this.$refs.serviceFilesRef.setData(this.serviceFiles)
				}
				if (this.$refs.serviceDocumentFilesRef) {
					this.$refs.serviceDocumentFilesRef.setData(this.serviceDocumentFiles)
				}
				this.$nextTick(() => {
					this.oldFormData = this.dumpFormData()
				})

			})

		} ,
		handleDeviceDefaultList(value) {
			if (this.canUpdatePreService) {
				this.devicesData.devices = [...value]
			}
		} ,
		dumpFormData() {
			const formData = {
				id : this.preService.id,
				...this.preServiceValue ,
				devicesData : {...this.devicesData},
				service_files: []
			}
			if (formData.inspected_by_id === undefined) {
				formData.inspected_by_id = null
			}
			if (this.$notEmpty(this.inspectedDate) && this.$notEmpty(this.inspectedTime)) {
				formData.inspected_datetime = this.inspectedDate + ' ' + this.inspectedTime+":00"
			} else {
				if (this.$notEmpty(this.inspectedDate)) {
					formData.inspected_datetime = this.inspectedDate + ' 10:00:00'
				} else {
					formData.inspected_datetime = null
				}
			}
			const checklistC = this.$refs.checklist
			if (checklistC && typeof checklistC.getData === 'function') {
				formData.checklistData = checklistC.getData()
			}
			const inspectC = this.$refs.inspection
			if (inspectC && typeof inspectC.getData === 'function') {
				formData.inspectionData = inspectC.getData()
			}
			if (this.$refs.serviceFilesRef) {
				formData.service_files.push({upload_type: 'pre-service', files: this.$refs.serviceFilesRef.getData()})
			}
			if (this.$refs.serviceDocumentFilesRef) {
				formData.service_files.push({upload_type: 'pre-service-doc', files: this.$refs.serviceDocumentFilesRef.getData()})
			}
			return formData
		} ,
		handleReset() {
			if (!this.canUpdatePreService)
				return
			const formData = this.dumpFormData()
			if (!this.isDataChange(formData)) {
				scrollTopContent()
				return
			}

			this.$confirm({
				title : this.$t('pre_service.action.ask_reset.title') ,
				content : this.$t('pre_service.action.ask_reset.message') ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk: ()=> {
					this.initPreService()
					scrollTopContent()
				} ,
			})
		} ,
		handleSave() {
			if (!this.canUpdatePreService)
				return
			const preFormData = this.dumpFormData()
			if (!this.isDataChange(preFormData)) {
				this.$message.info(this.$t('pre_service.action.update.no_change'))
				return
			}
			const serviceFiles = preFormData.service_files
			delete preFormData.service_files

			this.$confirm({
				title : this.$t('pre_service.action.update.confirm.title') ,
				content : this.$t('pre_service.action.update.confirm.message') ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk: ()=> {
					this.showPageLoading(true)
					// transform preFormData
					transformToJsonProp(preFormData,'devicesData','devices_json')
					transformToJsonProp(preFormData,'checklistData','checklist_json')
					transformToJsonProp(preFormData,'inspectionData','inspection_json')

					const formData = {
						'pre_service' : preFormData ,
						'service_files' : serviceFiles
					}

					axios.put("/api/services/"+this.service.id+"/pre-service",formData).then((response)=>{
						this.ignorePreventExit = true
						this.$message.success(this.$t('pre_service.action.update.confirm.success',{name:this.service.service_no}))
						this.$router.go()
					}).catch((error) => {
						this.saveError = error
						scrollTopContent()
					}).finally(()=>{
						this.hidePageLoading()
					})
				} ,
			})
		} ,
		preventExit(event) {
			if (this.checkPreventExit()) {
				event.preventDefault()
				event.returnValue = ""
			}
		},
		isDataChange(formData) {
			const change = !_isEqual(this.oldFormData,formData)
			return change
		} ,
		checkPreventExit(formData=null) {
			if (!this.canUpdatePreService || this.ignorePreventExit)
				return false
			if(!formData) {
				formData = this.dumpFormData()
			}
			return this.isDataChange(formData)
		}
	} ,

	beforeRouteLeave(routeTo,routeFrom,next) {
		const formData = this.dumpFormData()
		if (this.checkPreventExit(formData)) {
			this.$confirm({
				title : this.$t('pre_service.action.ask_leave.title') ,
				content : this.$t('pre_service.action.ask_leave.message') ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk: ()=> {
					next()
				} ,
			})
		} else {
			next()
		}
	},
}
</script>

<style lang="less" scoped>
.pre_service-subsection {
	margin-left : 64px;
	margin-bottom : 32px;
	.mobile & {
		margin-left : 16px;
	}
}
.pre_service-subsection-full {
	margin-bottom : 32px;
}

.pre_service-row {
	margin-bottom : 12px;
	label {
		color : @info-color;
		.mobile & {
			margin-bottom : 4px;
		}
	}
}
.pre_service-input-view {
	margin-left : 8px;
	display : inline-block;
}
.pre_service-input-block {
	display : inline-block;
	margin-right : 8px;
	.mobile & {
		display : block;
		margin-bottom : 4px;
		margin-right : 0;
	}
}
.drone-received-container {
	display: flex;
	margin-bottom: 12px;
	flex-direction : row;
	justify-content : space-between;
}
.pre_service-subsection-full {
	margin-bottom : 32px;
}
.right-button {
	margin: 0 0.2rem 0 auto;
}
.files-component {
	margin-right: 2rem;
}
.files-upload {
	display: flex;
	flex-direction: row;
	margin-right: 2rem;
}
</style>
